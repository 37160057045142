// vendors
import { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// constants
import PATHS from './paths.constants';

// styles
import './index.scss';

// views
const Intro = lazy(() => import('../pages/Intro'));
const GameView = lazy(() => import('../pages/Game'));
const GameInstructionsView = lazy(() => import('../pages/GameInstructions'));
const GameWinView = lazy(() => import('../pages/GameWin'));
const GameLossView = lazy(() => import('../pages/GameLoss'));
const SocialMediaView = lazy(() => import('../pages/SocialMedia'));
const FantaPrd = lazy(() => import('../pages/FantaPrd'));

const FantaRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className="loading-wrapper">
          <div className="loading-text">Loading...</div>
        </div>
      }
    >
      <BrowserRouter>
        <Routes>
          <Route key={PATHS.HOME} path={PATHS.HOME} element={<Intro />} />
          <Route key={PATHS.GAME} path={PATHS.GAME} element={<GameView />} />
          <Route
            key={PATHS.GAME_INSTRUCTIONS}
            path={PATHS.GAME_INSTRUCTIONS}
            element={<GameInstructionsView />}
          />
          <Route
            key={PATHS.GAME_WIN}
            path={PATHS.GAME_WIN}
            element={<GameWinView />}
          />
          <Route
            key={PATHS.GAME_LOSS}
            path={PATHS.GAME_LOSS}
            element={<GameLossView />}
          />
          {/* TODO: Update this route when PRODUCT2 path is updated */}
          <Route
            key={PATHS.PRODUCT2}
            path={PATHS.PRODUCT2}
            element={<SocialMediaView />}
          />
          <Route
            key={PATHS.FANTA_PRD}
            path={PATHS.FANTA_PRD}
            element={<FantaPrd />}
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default FantaRoutes;
