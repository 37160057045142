// components
import FantaRoutes from "./routes";

// styles
import "./App.scss";

const App = () => {
	return <FantaRoutes />;
};

export default App;
