const GAME = '/game';
const GAME_INSTRUCTIONS = '/instructions';
const GAME_LOSS = '/retry';
const GAME_WIN = '/score';
const HOME = '/';
const PRODUCT = `${process.env.REACT_APP_PRODUCT_URL}`;
const SWEEPSTAKES = `${process.env.REACT_APP_SWEEPSTAKES_URL}`;
const PRODUCT2 = '/product';
const FANTA_PRD = '/fanta-prd';

const PATHS = {
  GAME,
  GAME_INSTRUCTIONS,
  GAME_LOSS,
  GAME_WIN,
  HOME,
  PRODUCT,
  SWEEPSTAKES,
  PRODUCT2,
  FANTA_PRD,
};

export default PATHS;
